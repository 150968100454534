<template>
  <v-container fluid>
    <FilterDrawer
      @filter="filterOrderProducts"
      @clear="clearFilter"
      currentComponent="order-products-filter"
    />

    <Table :data="dataTable">
      <template v-slot:tbody="{ item }">
        <td>
          <ImageAvatar
            defaultIcon="fas fa-box"
            :img_src="
              item.budget_product.product.temp_image
                ? item.budget_product.product.temp_image
                : item.budget_product.product_images_selected_color.src
            "
          />
        </td>
        <td>
          <ul class="pa-0">
            <li>{{ item.budget_product.product.product_reference }}</li>
            <li>{{ item.budget_product.product.product_name }}</li>
            <li>
              <v-avatar
                color="primary"
                size="20"
              >
                <v-img
                  v-if="item.budget_product.color"
                  :src="
                    $store.state.imagePath +
                    item.budget_product.color.image_color
                  "
                />
                <v-icon
                  color="white"
                  x-small
                  v-else
                >
                  fas fa-palette
                </v-icon>
              </v-avatar>
              <span v-if="item.budget_product.color">
                {{ item.budget_product.color.name }}
              </span>
            </li>
          </ul>
        </td>
        <td>{{ item.alt_qtty ? item.alt_qtty : item.default_qtty }}</td>
        <td>
          <span v-if="showOrderProductsTotalValueColumn">
            {{
              item.alt_unitary
                ? parseFloat(
                    (
                      item.alt_unitary.replace(',', '.') * item.alt_qtty
                    ).toFixed(2)
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : parseFloat(
                    (item.default_unitary * item.default_qtty).toFixed(2)
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
            }}
          </span>
          <span v-else>***</span>
        </td>
        <td>
          <StatusChip
            :status="orderProductStatus"
            :currentStatus="item.order_product_status"
          />
        </td>
        <td>{{ item.order.budget.client.trading_name }}</td>
        <td>
          {{
            item.budget_product.print_id
              ? item.budget_product.print.name
              : 'Sem Gravação'
          }}
        </td>
        <td>
          <v-dialog
            width="600"
            v-model="openedArtDialog"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!item.art_src || !showOrderProductsSeeArtColumn"
                v-on="on"
                v-bind="attrs"
                icon
                color="primary"
              >
                <v-icon small>fas fa-eye</v-icon>
              </v-btn>
            </template>
            <v-card
              dark
              flat
            >
              <v-img
                :src="$store.state.imagePath + item.art_src"
                :lazy-src="$store.state.imagePath + item.art_src"
                max-width="100%"
                max-height="600"
                contain
              >
                <v-btn
                  class="white"
                  @click="openedArtDialog = false"
                  absolute
                  top
                  right
                  icon
                  color="error"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-img>
            </v-card>
          </v-dialog>
        </td>
        <td>
          <a
            @click="goToOrder(item.order_id)"
            class="primary--text font-weight-bold text-decoration-underline"
          >
            {{ 'Pedido n° ' + item.order_id }}
          </a>
        </td>
        <td class="text-center">
          <div>{{ item.order.delivery_date | formatDate }}</div>
          <div>{{ item.order.delivery_time }}</div>
        </td>
        <td>{{ item.order.budget.seller.name }}</td>
      </template>
    </Table>
  </v-container>
</template>
<script>
import { contextMenu, massAction } from '@/main.js'
import { mapGetters } from 'vuex'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import ImageAvatar from '@/components/data-table/ImageAvatar'
import StatusChip from '@/components/data-table/StatusChip'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    ImageAvatar,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    orderProductStatus: [
      {
        value: 41,
        text: 'Aguardando compra',
        color: 'blue-grey',
        dashed: false,
      },
      {
        value: 411,
        text: 'Parcialmente comprado',
        color: 'blue-grey',
        dashed: true,
      },
      {
        value: 42,
        text: 'Aguardando gravação',
        color: 'indigo',
        dashed: false,
      },
      {
        value: 421,
        text: 'Parcialmente gravado',
        color: 'indigo',
        dashed: true,
      },
      {
        value: 43,
        text: 'Aguardando conferência',
        color: 'cyan',
        dashed: false,
      },
      {
        value: 431,
        text: 'Parcialmente conferido',
        color: 'cyan',
        dashed: true,
      },
      {
        value: 44,
        text: 'Aguardando produção',
        color: 'deep-purple',
        dashed: false,
      },
      {
        value: 441,
        text: 'Parcialmente produzido',
        color: 'deep-purple',
        dashed: true,
      },
      {
        value: 45,
        text: 'Aguardando faturamento',
        color: 'orange',
        dashed: false,
      },
      {
        value: 451,
        text: 'Parcialmente faturado',
        color: 'orange',
        dashed: true,
      },
      {
        value: 46,
        text: 'Aguardando finalização',
        color: 'orange',
        dashed: false,
      },
      { value: 47, text: 'Finalizado', color: 'succes', dashed: false },
    ],
    openedArtDialog: false,
    dataTable: {
      title: 'Produtos do pedido',
      new: '',
      edit: '',
      context: 'order-products-context',
      massAction: 'order-products-mass-action',
      headerButtons: {
        filter: true,
        new: false,
        expandRow: true,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Imagem', value: 'order_product_image', width: 100 },
        {
          text: 'Nome',
          value: 'order_product_name',
          width: 300,
        },
        {
          text: 'QTD.',
          value: 'order_product_qtty',
          align: 'center',
        },
        { text: 'valor total', value: 'order_product_total', align: 'right' },
        { text: 'Status', value: 'order_product_status', align: 'center' },
        { text: 'Cliente', value: 'order.budget.client.trading_name' },
        { text: 'Gravação', value: 'order_product_print' },
        { text: 'Arte', value: 'order_product_art' },
        { text: 'Pedido', value: 'order_product_order_id', align: 'center' },
        {
          text: 'data de entrega',
          value: 'order_delivery_date',
          align: 'center',
        },
        {
          text: 'Vendedor',
          value: '',
          align: 'center',
        },
      ],
    },
  }),
  computed: {
    ...mapGetters('comercial', [
      'showOrderProductsTotalValueColumn',
      'showOrderProductsSeeArtColumn',
    ]),
  },
  methods: {
    goToOrder(id) {
      const routeData = this.$router.resolve({
        path: '/comercial/orders/' + id,
      })
      window.open(routeData.href, '_blank')
    },
    getOrderProducts() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('orders/products')
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterOrderProducts(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('orders/products/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getOrderProducts()
    },
  },
  mounted() {
    this.getOrderProducts()

    contextMenu.$once('action', (type) => {
      if (type === 'order-products') {
        this.getOrderProducts()
      }
    })
    massAction.$once('action', () => {
      this.getOrderProducts()
    })
  },
}
</script>
